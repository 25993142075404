<template>
    <ul>
        <li v-for="(el, i) in visibleValues" :key="i" :class="itemClass">
            <slot :el="el" :i="i">
                {{ el }}
            </slot>
        </li>
        <li v-show="!allShown && hiddenCount > 0" :class="[itemClass, 'small']">
            <span v-if="isStatic">
                <Icon v="plus-circle" />
                <T :params="{ count: hiddenCount }">profile.expendableList.more</T>
            </span>
            <a v-else href="#" @click.prevent="allShown = true">
                <Icon v="plus-circle" />
                <T :params="{ count: hiddenCount }">profile.expendableList.more</T>
                <template v-if="!(itemClass || '').includes('list-inline-item')">
                    <br>
                    <Icon v="spacer" />
                </template>
                <T>profile.expendableList.show</T>
            </a>
        </li>
    </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { useMainStore } from '../store/index.ts';

interface Data {
    allShown: boolean;
    showLimit: number;
    hiddenCount: number;
}

export default defineComponent({
    props: {
        values: { required: true, type: Array as PropType<string[]> },
        limit: { required: true, type: Number },
        reducedLimit: { default: 4, type: Number },
        isStatic: { type: Boolean },
        expand: { type: Boolean },
        itemClass: { default: undefined, type: String as PropType<string | undefined> },
    },
    setup() {
        return {
            store: useMainStore(),
        };
    },
    data(): Data {
        return {
            allShown: false,
            showLimit: this.values.length,
            hiddenCount: 0,
        };
    },
    computed: {
        reducedItems() {
            return this.store.reducedItems;
        },
        visibleValues(): string[] {
            if (this.allShown) {
                return this.values;
            }
            return this.values.slice(0, this.showLimit);
        },
    },
    watch: {
        expand(v) {
            if (v) {
                this.allShown = true;
            }
        },
        reducedItems() {
            this.updateData();
        },
    },
    created() {
        this.updateData();
    },
    methods: {
        updateData(): void {
            let showLimit = this.values.length;
            let hiddenCount = 0;
            const limit = this.reducedItems === undefined || this.reducedItems ? this.reducedLimit : this.limit;

            if (this.values.length > limit) {
                showLimit = limit;
                hiddenCount = this.values.length - limit;
            }
            if (hiddenCount === 1) {
                showLimit--;
                hiddenCount++;
            }

            this.allShown = this.expand;
            this.showLimit = showLimit;
            this.hiddenCount = hiddenCount;
        },
    },
});
</script>
