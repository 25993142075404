<template>
    <div>
        <ul class="list-inline small text-muted text-center mx-4">
            <li v-for="(opinion, key) in usedPredefinedOpinions" :key="key" class="list-inline-item">
                <Icon :v="opinion.icon" />
                =
                <T>profile.opinion.{{ key }}</T>
            </li>
        </ul>
        <ul v-if="Object.keys(usedCustomOpinions).length > 0" class="list-inline small text-muted text-center mx-4">
            <li class="list-inline-item">
                <T>profile.opinions.custom</T>
            </li>
            <li v-for="(opinion, key) in usedCustomOpinions" :key="key" class="list-inline-item">
                <Icon :v="opinion.icon" />
                =
                {{ opinion.description }}
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import opinions from '../src/opinions.ts';
import type { Opinion } from '../src/opinions.ts';

export default defineComponent({
    props: {
        custom: {
            default: () => {
                return {};
            },
            type: Object as PropType<Record<string, Opinion>>,
        },
        used: {
            default: () => {
                return new Set();
            },
            type: Set as PropType<Set<string>>,
        },
    },
    computed: {
        usedPredefinedOpinions(): Record<string, Opinion> {
            return Object.fromEntries(Object.entries(opinions).filter(([key, _]) => this.used.has(key)));
        },
        usedCustomOpinions(): Record<string, Opinion> {
            return Object.fromEntries(Object.entries<Opinion>(this.custom).filter(([key, _]) => this.used.has(key)));
        },
    },
});
</script>
