<template>
    <span v-if="op" :class="[op.style, `colour-${op.colour}`]">
        <Tooltip :text="op.description">
            <Icon :v="op.icon" />
        </Tooltip>
        <nuxt-link v-if="link" :to="link" :class="`colour-${op.colour || 'default'}`"><Spelling :escape="escape" :text="word" /></nuxt-link>
        <span v-else><Spelling :escape="escape" :markdown="markdown" :text="word" /></span>
        <Pronunciation v-if="pronunciation" :pronunciation="pronunciation" text />
    </span>
</template>

<script>
import opinions from '../src/opinions.ts';

export default {
    props: {
        word: { required: true },
        pronunciation: { default: null, type: String },
        opinion: { required: true },
        link: {},
        escape: { type: Boolean, default: () => true },
        customOpinions: { default: () => {
            return {};
        } },
        markdown: { type: Boolean },
    },
    data() {
        return {
            op: this.findOpinion(),
        };
    },
    methods: {
        findOpinion() {
            if (opinions.hasOwnProperty(this.opinion)) {
                return {
                    ...opinions[this.opinion],
                    description: this.$t(`profile.opinion.${this.opinion}`),
                };
            }

            for (const op of Object.values(this.customOpinions)) {
                if (op.icon === this.opinion) {
                    return op;
                }
            }

            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "assets/variables";

.small .fa-fw {
    width: #{$fa-fw-width / $small-font-size}em;
}
</style>
